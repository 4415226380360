import React, { useState, useEffect } from "react";

const CustomSelect = ({
  items,
  defaultTitle,
  selectEvent,
  selectedFirst = false,
}) => {
  const randomId = `customSelect_${Math.floor(Math.random() * 1000) + 1}`;
  const [state, setState] = useState({
    visibility: false,
    selectTitle: null,
    hoverIndex: -1,
  });

  const open = () => {
    setState((s) => ({ ...s, visibility: true }));
  };

  const close = (e) => {
    setState((s) => ({ ...s, visibility: false }));
  };

  const onKeyPress = (e) => {
    e.preventDefault();

    if (e.key === "ArrowUp") {
      if (state.hoverIndex < 1) return;
      setState({ ...state, hoverIndex: state.hoverIndex - 1 });
    } else if (e.key === "ArrowDown") {
      if (state.hoverIndex === items.length - 1) return;
      setState({ ...state, hoverIndex: state.hoverIndex + 1 });
    } else if (e.key === "Tab") {
      const nextEl = findNextTabElement();
      if (nextEl) nextEl.focus();
    } else if (e.key === "Enter") {
      if (state.hoverIndex < 0) return;
      if (state.visibility === false) open();
      else {
        const [id, selectTitle] = Object.entries(items[state.hoverIndex])[0];
        setState({ ...state, hoverIndex: -1, visibility: false, selectTitle });
        selectEvent({ target: { id } });
      }
    }
  };

  const findNextTabElement = () => {
    const el = document.querySelector(`#${randomId}`);
    const universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    const list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    const index = list.indexOf(el);
    return list[index + 1] || list[0];
  };

  const clickEvent = (e) => {
    setState({
      ...state,
      selectTitle: e.target.getAttribute("rel"),
      visibility: !state.visibility,
    });
    selectEvent(e.target.value);
  };

  //item 변경시 선택된 것도 변경하게
  useEffect(() => {
    setState({
      ...state,
      selectTitle: items[0],
    });
  }, [items]);

  useEffect(() => {
    if (items.length === 1 || (selectedFirst && items.length > 1)) {
      setState({
        ...state,
        selectTitle: items[0],
        visibility: false,
      });
      selectEvent(0);
    }
  }, [items.length]);

  return (
    <div className="select" id={randomId}>
      <div
        className={`cusSelect ${state.visibility ? "active" : ""}`}
        tabIndex={0}
        onFocus={open}
        onBlur={close}
        onKeyDown={onKeyPress}
      >
        {state.selectTitle == null ? defaultTitle : state.selectTitle}
      </div>
      <ul className={`options ${state.visibility ? "" : "hide"}`}>
        {items &&
          items.map((item, index) => (
            <li
              key={`${randomId}_${index}`}
              value={index}
              rel={item}
              onMouseDown={clickEvent}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CustomSelect;
