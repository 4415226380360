import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import StepList from 'components/campaign/manage/StepList';
import InfluencerItem from 'components/influencer/InfluencerItem';
import InfluencerDetailPopup from 'components/influencer/InfluencerDetailPopup';

import { setLoadingIndicatorVisibility } from 'store/actions/Common';
import ongoing from 'data/ongoing.json';
import influencer from 'data/influencer/basicInfo.json';

const sortInfluencers = (data, sort) => {
  let ordered;

  switch(sort) {
    case influencer.order[1]:
      ordered = data.sort((a,b) => { return b.avgView - a.avgView; });
      break;
    case influencer.order[2]:
      ordered = data.sort((a,b) => { return b.er - a.er; });
      break;
    case influencer.order[3]:
      ordered = data.sort((a,b) => { return b.globlinScore - a.globlinScore; });
      break;
    default:
      ordered = data.sort((a,b) => { return b.subsCount[b.subsCount.length - 1] - a.subsCount[a.subsCount.length - 1]; });
      break;
  }

  return ordered;
};

const Suggest = ({match}) => {
  const selectedInfluencer = useSelector(state => state.influencer);
  const selectedInfluencers = useSelector(state => state.influencerList);

  const [rawData, setRawData] = useState();
  const [orderBy, setOrderBy] = useState(influencer.order[0]);
  const [sortedInfluencers, setSortedInfluencers] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const all = sortInfluencers(ongoing.data, orderBy);
    setRawData(all);
    setSortedInfluencers(all.slice(0, 10));
  }, []);

  const orderHander = (sort) => {
    setSortedInfluencers(sortInfluencers(rawData, sort));
    setOrderBy(sort);
  };

  const influencerList = sortedInfluencers && sortedInfluencers.map((o, i) => {
    return (
      <InfluencerItem key={i} data={o} index={i}/>
    )
  });

  const showMore = () => {
    switch(sortedInfluencers.length) {
      case 30:
        alert('인플루언서 추가 추천은 최대 2회 제공 됩니다.');
        break;
      default:
        dispatch(setLoadingIndicatorVisibility(true));
        setSortedInfluencers(rawData.slice(0, sortedInfluencers.length + 10));

        window.scrollBy(0, -450);
        dispatch(setLoadingIndicatorVisibility(false));
    }
  };

  const handleNext = () => {
    if(selectedInfluencers.content.size === 0) {
      alert('최소 1명 이상의 인플루언서를 선택 하셔야 합니다.');
    }
  }

  return (
    <Fragment>
      <StepList id={match.params.id}/>
      <div className='campaign-wrap'>
				<div className='filter-list'>
					<button type='button' className={`${orderBy === influencer.order[0] ? 'on' : ''}`} onClick={() => orderHander(influencer.order[0])}>구독자순</button>
					<button type='button' className={`${orderBy === influencer.order[1] ? 'on' : ''}`} onClick={() => orderHander(influencer.order[1])}>조회수순</button>
					<button type='button' className={`${orderBy === influencer.order[2] ? 'on' : ''}`} onClick={() => orderHander(influencer.order[2])}>ER순</button>
					<button type='button' className={`${orderBy === influencer.order[3] ? 'on' : ''}`} onClick={() => orderHander(influencer.order[3])}>Score순</button>
				</div>
        {sortedInfluencers &&
				<div className='influencer-list'>
          {influencerList}
				</div>
        }
				<button type='button' className='btn-new-campaign' onClick={showMore}><span>인플루언서 추천받기</span></button>
			</div>
      <div className='content-btn-box'>
        <Link to='/campaign'>
          <button type='button' className='btn-line'>캠페인 목록</button>
        </Link>
        <Link to={`/campaign/manage/${match.params.id}/step2`}>
          <button type='button' className='btn-cont' onClick={handleNext}>다음</button>
        </Link>
			</div>
      {selectedInfluencer.show &&
        <InfluencerDetailPopup data={selectedInfluencer} />
      }
    </Fragment>
  );
};

export default Suggest;