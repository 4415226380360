import { setLoadingIndicatorVisibility } from "store/actions/Common";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import * as reportAPI from "../../api/reportList";

export const types = {};
types.GET_REPORT_LIST = "GET_REPORT_LIST";
types.GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS";
types.GET_REPORT_LIST_ERROR = "GET_REPORT_LIST_ERROR";

export function* getList({ payload }) {
  try {
    const data = yield call(reportAPI.getReportList);
    yield put({
      type: types.GET_REPORT_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: "SET_USER_ERROR",
      payload: { errCode: err.response.status },
    });
  }
}

export function* watchReportList() {
  yield takeEvery(types.GET_REPORT_LIST, getList);
}
