import React from 'react';
import Calendar, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import moment from 'moment';

registerLocale('ko', ko);
const DatePicker = (props) => {
  const { uploadDates = [] } = props;
  const prevMonth = moment().subtract(1, 'month').format('YYYY-MM');
  
  return (
    <Calendar
      locale='ko'
      className='datepicker'
      minDate={new Date(moment(prevMonth + '-01').format('YYYY-MM-DD'))}
      maxDate={new Date()}
      inline
      monthsShown={2}
      showPreviousMonths={true}
      shouldCloseOnSelect={false}
      highlightDates={uploadDates}
      selectsStart={false}
      selectsEnd={false}
    />
  );
}

export default DatePicker;

