import React from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Header = ({ location }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logout = (e) => {
    dispatch({ type: "LOGOUT" });
  };

  const isFit = location.pathname.startsWith("/fit");

  return (
    <div className="header">
      <div className="nav-bar">
        <Link to="/intro">
          <h1 className="logo">globlin</h1>
        </Link>
        <ul>
          <li>
            <NavLink to="/intro" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/campaign" activeClassName="active">
              Campaign
            </NavLink>
          </li>
          <li>
            <NavLink to="/report" activeClassName="active">
              Report
            </NavLink>
          </li>
          <li>
            <a className={`${isFit ? "active" : ""}`}>Fit-Fluencer</a>
            <div className="depth">
              <NavLink to="/fit/campaign" activeClassName="active">
                by 캠페인
              </NavLink>
              <NavLink to="/fit/channel" activeClassName="active">
                by 채널
              </NavLink>
            </div>
          </li>
        </ul>
        {user.loggedIn && (
          <div className="log-box">
            <span>{user.id}</span>
            <a>
              <button type="button" onClick={logout}>
                로그아웃
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
