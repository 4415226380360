import React, { Fragment, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateItem, deleteItem, updateList } from 'store/actions/ContentsList';

import StepList from 'components/campaign/manage/StepList';
import InfluencerItemForSelect from 'components/influencer/InfluencerItemForSelect'

import { getShortenNumber } from 'utils/NumberUtils';
import ongoing from 'data/ongoing.json';

const Content = (props) => {
	const { data, index } = props;
	
	const contentsList = useSelector(state => state.contentsList);
	const dispatch = useDispatch();

	const updateQuantity = (newValue) => {
		const payload = {
			idx: index,
			content: {
				...data,
				quantity: newValue
			}
		}

		dispatch(updateItem(payload));
	}

	const minusHandler = () => {
		if(data.quantity === 1) return false;
		updateQuantity(data.quantity - 1);
	}

	const plusHandler = () => {
		updateQuantity(data.quantity + 1);
	}

	const removeContent = () => {
		// const targetContent = contentsList.contents[index];
		// const result = contentsList.contents.find(c => c.influencer.id === influencer.id && c.type === type);

		// contentsList.contents.splice(index, 1);
		// console.log(contentsList)
		// const result = contentsList.contents.find(item => item.influencer.id === targetContent.influencer.id);
		// console.log(result)
		// if(result === undefined) {
		// 	contentsList.influencers.delete(targetContent.influencer.id);
		// }
		// const payload = {
		// 	contents: contentsList.contents.splice(index, 1),
		// 	influencers: contentsList.influencers,
		// 	sum: contentsList.sum - targetContent.quantity,
		// 	expectedView: contentsList.expectedView - targetContent.influencer.viewByDate[targetContent.influencer.viewByDate.length - 1] * targetContent.quantity
		// }

		dispatch(deleteItem({idx: index}));
	};

	return (
		<tr key={index}>
			<th>
				<div className='conts-type'>
					<span className='photo'><img src={data.influencer.profilePicUrl} alt={data.influencer.name}/></span>
					<strong>{data.influencer.name}</strong>
					{data.type}
				</div>
			</th>
			<td>
				<div className='cnt'>
					<button type='button' className='btn-minus' aria-label='감소' onClick={minusHandler}>-</button>
					<strong>{data.quantity}</strong>
					<button type='button' className='btn-plus' aria-label='증가' onClick={plusHandler}>+</button>
					<button type='button' className='btn-del' aria-label='삭제' onClick={removeContent} ></button>
				</div>
			</td>
		</tr>
	)
};

const Select = ({match}) => {
	const selectedInfluencerList = useSelector(state => state.influencerList);
	const contents = useSelector(state => state.contentsList);
	
	const [influencers, setInfluencers] = useState([]);
  useEffect(() => {
		const initialList = [...selectedInfluencerList.content].map((id, index) => {
			const influencer = ongoing.data.find(item => item.id === id);
			return <InfluencerItemForSelect key={index} data={influencer} index={index}/>
		})

    // const initialList = american.data.slice(1, 5).map((e, i) => (<InfluencerItemForSelect key={i} data={e} index={i}/>));
    setInfluencers(initialList);
  }, [])


	const contentsList = contents.contents.map((c, i) => <Content key={i} data={c} index={i} />);

  return (
    <Fragment>
      <StepList />
      <div className='campaign-mng'>
				<div className='influencer-item-list'>
					<div className='influencer-scroll'> 
						{influencers.length === 0 &&
							<div className='influencer-empty'>선택된 인플루언서가 없습니다.</div>
						}
						{influencers}
					</div>
				</div>
				<div className='selected-wrap'>
					<h2 className=''>선택리스트</h2>
					<div className='table-box-wrap'>
						<div className='tablel-box'>
						<table>
							<colgroup>
								<col style={{width: '256px'}}/>
								<col style={{width: 'auto'}}/>
							</colgroup>
							<thead>
								<tr>
									<th scope='col'>콘텐츠</th>
									<th scope='col'>수량</th>
								</tr>
							</thead>
							<tbody>
								{contentsList}
							</tbody>
						</table>
						</div>
					</div>
					<div className='total-cnt'>
						<strong className='title'>Total</strong>
						<div className='total-info'>
							<span>인플루언서<strong>{contents.influencers.size}</strong></span>
							<span>콘텐츠 수량<strong>{contents.sum}</strong></span>
							<span>예상 조회수<strong>{getShortenNumber(contents.expectedView)}</strong></span>
						</div>
					</div>
				</div>
			</div>
      <div className='content-btn-box'>
        <Link to={`/campaign/manage/${match.params.id}/step1`}>
          <button type='button' className='btn-line'>이전</button>
        </Link>
				<Link to={`/campaign/manage/${match.params.id}/step3`}>
          <button type='button' className='btn-cont'>다음</button>
        </Link>
			</div>
    </Fragment>
  );
};

export default withRouter(Select);