import React, { Fragment } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Link, withRouter } from 'react-router-dom';

import campaignList from 'data/campaign/list.json';
import CampaignItem from 'components/campaign/CampaignItem';

const CampaignListPage = () => {
  const campaignItemList = campaignList.map((c, i) => (
    <CampaignItem key={i} data={c} />
  ));

  return (
    <Fragment>
      <Header/>
      <div className='content-wrap'>
        <div className='campaign-wrap'>
          {campaignItemList}
          <Link to='/campaign/regist/step1'>
            <button type='button' className='btn-new-campaign'><span>캠페인 추가하기</span></button>
          </Link>
        </div>
      </div>
      <Footer/>
    </Fragment>
  )
};

export default withRouter(CampaignListPage);