import React, { useState, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Footer from "components/Footer";

import campaign1 from "images/intro/campaign-1.png";
import campaign2 from "images/intro/campaign-2.png";
import intro2 from "images/intro/intro2.png";
import matching1 from "images/intro/matching-result-1.png";
import matching2 from "images/intro/matching-result-2.png";
import fit1 from "images/intro/fit-fluencer-1.png";
import fit2 from "images/intro/fit-fluencer-2.png";

const defaultUser = {
  id: null,
  email: null,
  snsProvider: null,
  snsId: null,
  authorities: [
    {
      authority: null,
    },
  ],
};

const IntroPage = ({ history }) => {
  const [user, setUser] = useState(defaultUser);
  let hasLoggedIn = false;
  try {
    // hasLoggedIn = user.authorities.find(auth => auth.authority === 'ROLE_USER');
    hasLoggedIn = ["ROLE_ADMIN", "ROLE_USER"].includes(user.role);
  } catch (e) {
    hasLoggedIn = false;
  }

  return (
    <Fragment>
      <div>
        <div className="main-intro-wrap">
          <div className="intro-top">
            <div className="inner">
              <p className="top-txt">
                인플루언서 마케팅부터 퍼포먼스 마케팅까지
                <br />
                글로벌 올인원 마케팅
              </p>
              <div className="main-stand">
                Global Influencer Self-Serving Platform
                <strong className="logo">
                  <span className="hide">globblin</span>
                </strong>
                <Link to="/logIn">
                  <button type="button" className="btn-start">
                    시작하기
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="main-info">
            <p>
              글로블린은 퍼포먼스 기반 인플루언서 매칭과
              <br />
              분석 데이터를 통해 캠페인을 집행할 수 있는
            </p>
            <strong>
              국내 유일 <br />
              글로벌 인플루언서 광고 플랫폼입니다.
            </strong>
          </div>
          <div className="main-conts">
            <strong>캠페인 집행이 진짜 가능한 글로벌 인플루언서 매칭</strong>
            <p>
              500회 이상의 글로벌 인플루언서 <br className="mbr" />
              마케팅 집행 데이터를 기반으로
              <br />
              캠페인이 진짜 가능한 인플루언서를 매칭해드려요.
            </p>
            <div className="img-box">
              <div className="first">
                <img src={campaign1} alt="campaign1" />
                <img src={campaign2} alt="campaign2" />
              </div>
              <div className="second">
                <img src={matching1} alt="matching1" />
                <img src={matching2} alt="matching2" />
              </div>
            </div>
          </div>
          <div className="main-conts bg-white">
            <strong>DM과 메일은 이제 그만, 실시간 셀프 서빙 플랫폼</strong>
            <p>
              클릭 몇번으로 스케줄 관리, 컨셉 협의와 같은 커뮤니케이션을
              <br />
              심플하게 관리하세요.
            </p>
            <img src={intro2} alt="calendar" />
          </div>
          <div className="main-conts">
            <strong>Globlin 유사도 분석 봇의 Fit-Fluencer 최적화 시스템</strong>
            <p>
              내 캠페인에서 성과가 좋은 인플루언서와 <br className="mbr" />
              핏이 비슷한 인플루언서를 찾아보세요.
              <br />
              최적화된 인플루언서 캠페인에 도움을 드려요.
            </p>
            <div className="img-box">
              <div className="third">
                <img src={fit1} alt="fit1" />
              </div>
              <div className="fourth">
                <img src={fit2} alt="fit2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(IntroPage);
