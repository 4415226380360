import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import DatePicker from 'components/DatePicker';

import { updateItem, deleteItem } from 'store/actions/ContentsList';
import { getShortenNumber } from 'utils/NumberUtils';
import moment from 'moment';
import baseContent from 'data/base/content.json';

const ContentItem = (props) => {
	const { idx, data, index, rowSpan, contentType, status: statusCode } = props;

  const contents = useSelector(state => state.contentsList);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const minDate = new Date(moment().add(14, 'days').format('YYYY-MM-DD'));

  const [status, setStatus] = useState(statusCode);
  const [showPrice, setShowPrice] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showDisplayStatus, setShowDisplayStatus] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const displayPrice = status > 1 ? `₩${Intl.NumberFormat('en-US').format(data.price)}` : '';
  const expectedSchedule = status > 1 ? ( endDate ? moment(endDate).format('MM/DD') : '') : '스케줄 미확정';
  const displayStatus = baseContent.arrangeStatus[status];

  const updateStatus = (status) => {
    const payload = {
      idx: idx,
      content: {...contents.contents[idx], status: status}
    }
    
    dispatch(updateItem(payload));
  }

  const handleConfirm = () => {
    setStatus(3);
    updateStatus(3);

    setBtnDisabled(true);
  }

  const handleDelete = () => {
    alert('삭제된 인플루언서는 추천리스트에서 다시 확인할 수 있습니다.')

    const payload = {
      idx: idx
    }
    
    dispatch(deleteItem(payload));
  }

  const handlePrice = () => {
    setShowPrice(true);
    setTimeout(function() {
      handleSchedule();
    }, 200);
  }
  
  const handleSchedule = () => {
    setShowSchedule(true);
    setTimeout(function() {
      handleDisplayStatus();
    }, 200);
  }

  const handleDisplayStatus = () => {
    setShowDisplayStatus(true);

    if(status !== 2 || endDate === null) {
      setBtnDisabled(false);
    }
  }

  const handleDate = () => {
    updateStatus(status);
    setTimeout(function() {
      handlePrice();
    }, 3000);
  }

	return (
    <tr>
    {index === 0 &&
      <Fragment>
        <td rowSpan={rowSpan}>
          <div className='name-tag'>
            <span><img src={data.profilePicUrl} alt={data.name}/></span>{data.name}
          </div>
        </td>
        <td rowSpan={rowSpan}>{getShortenNumber(data.subsCount[data.subsCount.length - 1])}</td>
        <td rowSpan={rowSpan}>{getShortenNumber(data.avgView)}</td>
      </Fragment>
    }
      <td>{contentType}</td>
      <td>
        <div className={`inp-date`}>
          <DatePicker 
            minDate={minDate}
            today={false}
            startDate={startDate} 
            setStartDate={setStartDate} 
            endDate={endDate} 
            setEndDate={setEndDate} 
            inline={false}
            handleChange={handleDate}/>
        </div>
      </td>
      <td style={{textAlign:'right'}}>{showPrice ? displayPrice : ''}</td>
      <td>{showSchedule ? expectedSchedule : ''}</td>
      <td><span className='txt-make'>{showDisplayStatus ? displayStatus : ''}</span></td>
      <td><button type='button' className='btn-msg' aria-label='메세지'></button></td>
      <td>
        <button type='button' className='btn-cont xsmall' disabled={btnDisabled} onClick={handleConfirm}>확정</button>
        <button type='button' className='btn-sub xsmall' style={{marginLeft: '4px'}} disabled={btnDisabled} onClick={handleDelete}>삭제</button>
      </td>
    </tr>
  );
};

export default withRouter(ContentItem);