import { types } from 'store/sagas/Campaign';

export const save = (payload) => ({
  type: types.SAVE,
  payload
});

export const create = (payload) => ({
  type: types.CREATE,
  payload
});

