import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StepList from 'components/campaign/manage/StepList';
import ContentItem from 'components/ContentItem';

const Arrange = ({match}) => {
  const contents = useSelector(state => state.contentsList);

	let selectedContentsList = [];
	let idx = 0;

  [...contents.influencers].forEach((id, index) => {
		const currentInfluencerList = contents.contents.filter(c => c.influencer.id === id);

		const contentsItemList = currentInfluencerList.map((c, i) => {
			let status = 2;
			// 맨 첫번째 항목은 협의중으로 만들기 위해 수작업으로 가공한다
			if(idx === 0) {
				status = 1;
			}
			
			// 맨 마지막 항목은 진행불가로 만들기 위해 수작업으로 가공한다
			if(idx === contents.contents.length - 1) {
				status = 0;
			}

			idx++;
			const rowSpan = currentInfluencerList.length;
			return (
				<ContentItem key={`${c.influencer.name}_${i}`} idx={idx-1} data={c.influencer} index={i} rowSpan={rowSpan} contentType={c.type} status={status}/>
			);
		});

		selectedContentsList = selectedContentsList.concat(contentsItemList);
	});

  return (
    <Fragment>
      <StepList />
      <div className='table-wrap'>
				<table className='tbl-data'>
					<colgroup>
						<col style={{width:'auto'}}/>
						<col style={{width:'90px'}}/>
						<col style={{width:'90px'}}/>
						<col style={{width:'140px'}}/>
						<col style={{width:'132px'}}/>
						<col style={{width:'100px'}}/>
						<col style={{width:'120px'}}/>
						<col style={{width:'90px'}}/>
						<col style={{width:'90px'}}/>
						<col style={{width:'160px'}}/>
					</colgroup>
					<thead>
						<tr>
							<th scope='col'>이름</th>
							<th scope='col'>구독자수</th>
							<th scope='col'>평균 조회수</th>
							<th scope='col'>캠페인 유형</th>
							<th scope='col'>희망 일정</th>
							<th scope='col'>금액</th>
							<th scope='col'>
                예상 일정
                <div className='tooltip-box'>도움말
                  <div className='tooltip-base'>
                    캠페인 신청시 정해진 일정을 기반으로 인플루언서들이<br/>예상 일정을 전달합니다.
                  </div>
                </div>
              </th>
							<th scope='col'>
                진행 상황
                <div className='tooltip-box'>도움말
                  <div className='tooltip-base'>
                    '확정대기중'인 인플루언서는 3일내에 변경/확정<br/>해주셔야 합니다. 미확정한 인플루언서는 삭제되어<br/>다시 추천 항목으로 이동돼요.
                  </div>
                </div>
              </th>
							<th scope='col'>메세지</th>
							<th scope='col'>확정</th>
						</tr>
					</thead>
					<tbody>
						{selectedContentsList}
					</tbody>
				</table>
			</div>
      <div className='content-btn-box'>
        <Link to={`/campaign/manage/${match.params.id}/step2`}>
          <button type='button' className='btn-line'>이전</button>
        </Link>
				<Link to={`/campaign/manage/${match.params.id}/step4`}>
          <button type='button' className='btn-cont'>다음</button>
        </Link>
			</div>
    </Fragment>
  );
};

export default Arrange;