import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "./Routes";
import history from "utils/CustomHistory";
import LoadingIndicator from "components/LoadingIndicator";
import "react-datepicker/dist/react-datepicker.css";
import "css/datepicker.css";
import "css/datepickerForDetail.css";
import "css/datepickerForArrange.css";

const LoginChecker = ({ children }) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { pathname } = useLocation();
  const allowPaths = ["/", "/intro", "/login"];
  const dispatch = useDispatch();

  //새로고침 마다 확인
  useEffect(() => {
    dispatch({ type: "SET_USER" });
  }, []);

  //도메인이 바뀔때마다 확인해서 리다이렉션
  useEffect(() => {
    if (!user.loggedIn && !allowPaths.includes(pathname)) {
      history.push("/login");
    }
  });

  //user가 변화시마다 체크해서 페이지로 ㄲ
  useEffect(() => {
    if (user.loggedIn) {
      //이 초기 페이지는 나중에 수정할 수 있음.
      history.push("/report");
    }
  }, [user]);

  return children;
};

function App() {
  return (
    <Router history={history}>
      <Switch>
        <LoginChecker>
          {routes.map((r) => (
            <Route
              key={r.path}
              path={r.path}
              component={r.component}
              strict
              exact={r.isExact}
            />
          ))}
        </LoginChecker>
      </Switch>
      <LoadingIndicator />
    </Router>
  );
}

export default App;
