import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { save } from 'store/actions/Campaign';

import StepList from 'components/campaign/regist/StepList';
import CustomSelect from 'components/CustomSelect';
import base from 'data/campaign/basicInfo.json';
import CampaignPopup from 'components/campaign/regist/CampaignPopup';

const ContentsType = (props) => {
	const { index, type, removeHandler } = props;

	return (
		<span key={index}>{type}
			<button type='button' value={type} className='btn-del' aria-label='삭제' onClick={removeHandler}/>
		</span>
	)
};

const TypeSelect = () => {
	const campaign = useSelector(state => state.campaign);
  const dispatch = useDispatch();

	const [platform, setPlatform] = useState();
	const [contentsType, setContentsType] = useState();
	const [contentsTypes, setContentsTypes] = useState([]);
	const [preferedSubscriberCount, setPreferedSubscriberCount] = useState(-1);
	
	const [showPopup, setShowPopup] = useState(false);

  const platformHandler = (index) => {
		const selected = base.platforms[index];
		setPlatform(selected);

		if(contentsType) {
			const created = `${selected}_${contentsType}`;
			
			if(!contentsTypes.includes(created)) {
				contentsTypes.push(created);
			}
		}
  };

  const contentsHandler = (index) => {
		const selected = base.contentsTypes[index].split(' (')[0];
		setContentsType(selected);

		if(platform) {
			const created = `${platform}_${selected}`;
			
			if(!contentsTypes.includes(created)) {
				contentsTypes.push(created);
			}
		}
  };

	const subscriberHandler = (e) => {
		setPreferedSubscriberCount(e.currentTarget.value);
  };

  const showConfirmDialog = () => {
		setShowPopup(true);
	};
	
	const removeHandler = (e) => {
		const target = e.currentTarget.value;
		contentsTypes.pop(target);
		setContentsTypes(contentsTypes.slice());
  };

	const validate = () => {
    if(!platform) return false;
		if(contentsTypes.length === 0) return false;
		if(preferedSubscriberCount === -1) return false;
		
    return true;
	};
	
	const saveHandler = () => {
		if(!validate()) {
			alert('필수 값을 모두 입력/선택 해주세요.');
			return;
		}


    const payload = {
			contentsTypes: contentsTypes,
  		preferedClass: preferedSubscriberCount,
    };
    dispatch(save(payload));

		showConfirmDialog();
    // history.push('/campaign/regist/step2');
  };

  return (
    <Fragment>
      <StepList />
      <div className='content-box ps-center'>
				<div className='form-wrap'>
					<div className='flex-box'>
						<div className='form-box'>
							<label className='label-txt' htmlFor='name'>플랫폼</label>
              <CustomSelect items={base.platforms} defaultTitle='플랫폼을 선택해주세요.' selectEvent={platformHandler}/>
						</div>
						<div className='form-box'>
							<label className='label-txt' htmlFor='nation'>콘텐츠 유형</label>
              <CustomSelect items={base.contentsTypes} defaultTitle='카테고리를 선택해주세요.' selectEvent={contentsHandler}/>
						</div>
					</div>
					<div className='lang-sel-list'>
					{contentsTypes.length === 0 ? (
						<p>플랫폼과 콘텐츠 유형을 모두 선택해야 추가됩니다.</p>
					) : (
						contentsTypes.map((c, i) => <ContentsType key={i} type={c} removeHandler={removeHandler}/>)
					)}
					</div>
					<div className='form-box'>
						<span className='label-txt'>선호 인플루언서 규모</span>
						<div className='inp-group'>
							<input type='radio' name='inp' id='r1' value='0' onChange={subscriberHandler}/>
							<label className='lb-radio' htmlFor='r1'><span>{base.subscriberCounts[0]}</span></label>
							<input type='radio' name='inp' id='r2' value='1' onChange={subscriberHandler}/>
							<label className='lb-radio' htmlFor='r2'><span>{base.subscriberCounts[1]}</span></label>
							<input type='radio' name='inp' id='r3' value='2' onChange={subscriberHandler}/>
							<label className='lb-radio' htmlFor='r3'><span>{base.subscriberCounts[2]}</span></label>
							<input type='radio' name='inp' id='r4' value='3' onChange={subscriberHandler}/>
							<label className='lb-radio' htmlFor='r4'><span>{base.subscriberCounts[3]}</span></label>
							<input type='radio' name='inp' id='r5' value='4' onChange={subscriberHandler}/>
							<label className='lb-radio' htmlFor='r5'><span>{base.subscriberCounts[4]}</span></label>
						</div>
					</div>
				</div>
			</div>
      <div className='content-btn-box'>
        <Link to='/campaign/regist/step2'>
          <button type='button' className='btn-line'>이전</button>
        </Link>
        <button type='button' className='btn-cont' style={{marginLeft: '96px'}} onClick={saveHandler}>저장</button>
			</div>
			{showPopup &&
				<CampaignPopup data={campaign} onClose={() => {setShowPopup(false);}}/>
			}
    </Fragment>
  );
};

export default TypeSelect;