import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import InfluencerChart from 'components/influencer/InfluencerChart';
import DatePickerForDetail from 'components/DatePickerForDetail';

import { getShortenNumber } from 'utils/NumberUtils';
import { hideDetail } from 'store/actions/Influencer';

const Thumbnail = (props) => {
	const { index, link, name, url, view, date } = props;

	return (
		<li>
			<a href={link} target='_blank' rel='noopener noreferrer'>
				<span className='img'>
					<img src={url} alt={`${name}_${index}`} style={{width: '238px', height: '134px', objectFit: 'cover'}}/>
				</span>
				<p>
					<span>조회수 {getShortenNumber(view)}</span><strong className='date'>{date.substring(0, 4)}-{date.substring(4, 6)}-{date.substring(6)}</strong>
				</p>
			</a>
		</li>
	);
};

const InfluencerDetailPopup = (props) => {
	const dispatch = useDispatch();
  const { data } = props;

	const thumbnails = data.content4weeks.map((c, i) => <Thumbnail key={i} index={i} name={data.name} url={c.thumbnailUrl} link={c.videoUrl} view={c.viewCount} date={c.date}/> );

	const dateList = [];
	for(let i=0;i<13;i++) {
		const date = moment().add(i - 14, 'days').format('YYYY-MM-DD');
		dateList.push(date);
	};

	const chartData = {
		labels: dateList,
		datasets: [
			{
				label: false,
				yAxisID: 'y1',
				fill: false,
				backgroundColor: 'rgba(255,255,255,1)',
				borderColor: 'rgba(0, 176, 116, 1)',
				pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
				pointHoverBorderWidth: 3,
				pointRadius: 2,
				pointHoverRadius: 6,
				pointHoverBorderColor: 'rgba(219, 95, 51, 1)',
				data: data.subsCount
			}
		]
	};

	const uploadDates = data.content4weeks.map((c, i) => {
		return new Date(moment(c.date).format('YYYY-MM-DD'));
	});

	const handleOutsideClick = (e) => {
		e.preventDefault();
		hidePopup();
	}

	const hidePopup = () => {
		dispatch(hideDetail());
	}

  return (
		<div className='pop-wrap large'>
			<div style={{width:'100%', height:'100%', background:'transparent'}} onClick={handleOutsideClick}></div>
			<div className='pop-content'>
				<div className='influencer-info'>
					<span className='photo'>
						<a href={data.channelUrl} target='_blank' rel='noopener noreferrer' >
							<img src={data.profilePicUrl} alt={data.name} style={{width: '155px'}}/>
						</a>
					</span>
					<div className='info-wrap'>
						<div className='title'>
							<span className='cate'>{data.category}</span>
							<strong>{data.name}</strong>
						</div>
						<div className='use-platform'>
							<a href={data.channelUrl} target='_blank' rel='noopener noreferrer' >
								<span className='youtube'>유튜브</span>
							</a>
							{data.facebook &&
								<span className='facebook'>페이스북</span>
							}
							{data.instagram &&
								<span className='instagram'>인스타그램</span>
							}
							{data.twitter &&
								<span className='twitter'>트위터</span>
							}
						</div>
					</div>
				</div>
				<strong className='pop-h2'>채널 성장 추이</strong>
				<div className='inner-conts'>
					<div className='graph-box'>
						<div className='y-axis'>구독자수</div>
						<InfluencerChart data={chartData} />
					</div>
				</div>
				<strong className='pop-h2'>포스팅 주기</strong>
				<div className='inner-conts'>
					<div className='post-wrap'>
						<p>최근 4주간 업로드된 콘텐츠 수는 <span>4개</span> 입니다.</p>
						<div className='line-conts-box'>
							<div className='legends'>
								업로드일 <div className='upload'/> 오늘<div className='today'/>
							</div>
							<DatePickerForDetail uploadDates={uploadDates} />
						</div>
					</div>
				</div>
				<strong className='pop-h2'>썸네일</strong>
				<div className='inner-conts'>
					<ul className='thumnail-list-wrap'>
						{thumbnails}
					</ul>
				</div>
				<button type='button' className='btn-close' onClick={hidePopup}>창닫기</button>
			</div>
		</div>
  );
};

export default InfluencerDetailPopup;