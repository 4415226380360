import {call, put, takeLeading, select} from 'redux-saga/effects';
import {setLoadingIndicatorVisibility} from 'store/actions/Common';

export const types = {};
types.SHOW_DETAIL = 'SHOW_DETAIL';
types.HIDE_DETAIL = 'HIDE_DETAIL';

export function* showDetailInfo({ payload }) {

};

export function* watchInfluencer() {
    yield takeLeading(types.SHOW_DETAIL, showDetailInfo);
}