import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getShortenNumber } from 'utils/NumberUtils';
import { showDetail } from 'store/actions/Influencer';
import { updateList } from 'store/actions/InfluencerList';

const Thumbnail = (props) => {
	const { index, link, name, url } = props;

	return (
		<a href={link} target='_blank' rel='noopener noreferrer' style={{marginTop: '10px'}}>
			<span key={index}>
				<img src={url} alt={`${name}_${index}`} style={{width: '122px', height: '64px', objectFit: 'cover'}}/>
		</span>
		</a>
	);
};

const InfluencerItem = (props) => {
	const selectedInfluencerList = useSelector(state => state.influencerList);
	const dispatch = useDispatch();

	const { data, index } = props;

	const makeRandomBoolean = () => {
		return Math.floor(Math.random() * 10) % 2 === 0 ? true : false; 
	};

	const influencer = {
		...data,
		"tags": data.tags.map((t, i) => (<span key={i}>#{t}</span>)),
		"thumbnails": data.content4weeks.map((c, i) => <Thumbnail key={i} index={i} name={data.name} url={c.thumbnailUrl} link={c.videoUrl}/> ),
		"facebook": makeRandomBoolean() ,
		"instagram": makeRandomBoolean(),
		"twitter": makeRandomBoolean()
	};

	const showDetailInfo = () => {
		const payload = {
			...influencer,
			show: true
		}

		dispatch(showDetail(payload));
	}

	const handleCheck = (id) => {
		const selectedSet = selectedInfluencerList.content;

		if(selectedSet.has(id)) {
			selectedSet.delete(id);
		} else {
			selectedSet.add(id);
		}

		dispatch(updateList(selectedInfluencerList));
	};

  return (
    <div className='influencer-item'>
			<div className='profile'>
				<span className='photo'>
					<a href={influencer.channelUrl} target='_blank' rel='noopener noreferrer' >
						<img src={influencer.profilePicUrl} alt={influencer.name} />
					</a>
				</span>
				<div className='use-platform'>
					<a href={influencer.channelUrl} target='_blank' rel='noopener noreferrer' >
						<span className='youtube'>유튜브</span>
					</a>
					{influencer.facebook &&
						<span className='facebook'>페이스북</span>
					}
					{influencer.instagram &&
						<span className='instagram'>인스타그램</span>
					}
					{influencer.twitter &&
						<span className='twitter'>트위터</span>
					}
				</div>
			</div>
			<div className='info-wrap'>
				<div className='title'>
					<span className='cate'>{influencer.category}</span>
					<strong>{influencer.name}</strong>
				</div>
				<div className='tagbox'>
					{influencer.tags}
				</div>
				<div className='inf-index'>
					<ul>
						<li>
							<span>구독자</span><strong>{getShortenNumber(influencer.subsCount[influencer.subsCount.length - 1])}</strong>
						</li>
						<li>
							<span>AVG. View
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										최근 20개 동영상의 평균 조회수
									</div>
								</div>	
							</span>
							<strong>{getShortenNumber(influencer.avgView)}</strong>
						</li>
						<li>
							<span>ER
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										최근 20개 동영상의 "(좋아요 + 싫어요 + 댓글) / 조회수" 값
									</div>
								</div>	
							</span>
							<strong>{influencer.er}%</strong>
						</li>
						<li>
							<span>Score
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										자체적으로 부여한 캠페인 적합 점수
									</div>
								</div>	
							</span>
							<strong className={`star${influencer.globlinScore}`}></strong>
						</li>
						<li>
							<span>타겟국가</span><strong>{influencer.region}</strong>
						</li>
						<li>
							<span>언어</span><strong>{influencer.language}</strong>
						</li>
					</ul>
					<div className='thumbnail'>
						{influencer.thumbnails}
					</div>
				</div>
			</div>
			<div className='check'>
				<input type='checkbox' id={`chk_influencer_${index}`} onClick={e=>{handleCheck(data.id);}} defaultChecked={selectedInfluencerList.content.has(data.id)}/>
				<label className='lb-check' htmlFor={`chk_influencer_${index}`}>
					<span>선택</span>
				</label>
			</div>
			<button type='button' className='btn-line middle' onClick={showDetailInfo}>세부정보</button>
		</div>
  )
};

export default withRouter(InfluencerItem);