import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import StepList from 'components/campaign/manage/StepList';
import ReviewItem from 'components/ReviewItem';

const Review = ({match}) => {
  const contents = useSelector(state => state.contentsList);

  let selectedContentsList = [];
  let idx = 0;

  [...contents.influencers].forEach((id, index) => {
		const currentInfluencerList = contents.contents.filter(c => c.influencer.id === id && c.status === 3);

		const contentsItemList = currentInfluencerList.map((c, i) => {
      idx++;
			return (
				<ReviewItem key={`${c.influencer.name}_${i}`} idx={idx-1} data={c.influencer} index={i} contentType={c.type} status={c.status}/>
			);
		});

		selectedContentsList = selectedContentsList.concat(contentsItemList);
  });
  
  return (
    <Fragment>
      <StepList />
      <div className='review-wrap'>
        {selectedContentsList}
			</div>
      <div className='content-btn-box'>
        <Link to={`/campaign/manage/${match.params.id}/step3`}>
          <button type='button' className='btn-line'>이전</button>
        </Link>
				<Link to={`/campaign`}>
          <button type='button' className='btn-cont'>홈으로</button>
        </Link>
			</div>
    </Fragment>
  );
};

export default Review;