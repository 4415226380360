import React from 'react';
import Calendar, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import moment from 'moment';

registerLocale('ko', ko);
const DatePicker = (props) => {
  const { startDate, setStartDate, minDate, endDate, setEndDate, today = true, inline = true, shouldCloseOnSelect = false, handleChange } = props;

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if(handleChange) handleChange();
  };

  const displayDate = startDate && endDate ? `${moment(startDate).format('MM/DD')}~${moment(endDate).format('MM/DD')}` : '';

  return (
    <Calendar
      locale='ko'
      dateFormat='MM/dd'
      className='datepicker'
      minDate={minDate}
      selected={today ? new Date() : null}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      inline={inline}
      selectsRange
      monthsShown={2}
      shouldCloseOnSelect={shouldCloseOnSelect}
      value={displayDate}
    />
  );
}

export default DatePicker;

