import { takeLeading } from "redux-saga/effects";
// import { setLoadingIndicatorVisibility } from 'store/actions/Common';

export const types = {};
types.SET_TARGET = "SET_TARGET";
types.SET_CANDIDATE = "SET_CANDIDATE";
types.FIND = "FIND";

export function* setTarget({ payload }) {}

export function* setCandidate({ payload }) {}

export function* find({ payload }) {}

export function* watchFit() {
  yield takeLeading(types.SET_TARGET, setTarget);
  yield takeLeading(types.SET_CANDIDATE, setCandidate);
  yield takeLeading(types.FIND, find);
}
