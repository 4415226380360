import { types } from 'store/sagas/Campaign';
const initialState = {
  id: -1,
  status: 'matching',
  title: '',
  createdAt: null,
  thumbnailUrl: '',
  category: -1,
  targetRegion: -1,
  language: [],
  budget: -1,
  startDate: null,
  endDate: null,
  etc: '',
  purpose: '',
  contentsTypes: [],
  preferedClass: -1,
};

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.CREATE:
      return initialState;
    default:
      return state;
  }
}

export default userReducer;

