import { types } from 'store/sagas/Fit';

export const setTarget = (payload) => ({
  type: types.SET_TARGET,
  payload
});

export const setCandidate = (payload) => ({
  type: types.SET_CANDIDATE,
  payload
});

export const find = (payload) => ({
  type: types.FIND,
  payload
});
