import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import campaignBase from 'data/campaign/basicInfo.json';

const CampaignItem = (props) => {
	const { data } = props;

	const getStatusText = (value) => {
		switch(value) {
			case "new":
				return "신규";
			case "matching":
				return "매칭중";
			case "ongoing":
				return "진행중";
			case "complete":
			default:
				return "완료";
		}
	}

	const getButtonText = (value) => {
		switch(value) {
			case "new":
				return "신규";
			case "matching":
				return "매칭중";
			case "ongoing":
				return "시작하기";
			case "complete":
			default:
				return "리포트 보기";
		}
	}

	const campaign = {
		...data,
		status: getStatusText(data.status),
		category: campaignBase.categories[data.category],
		targetRegion: campaignBase.targetRegion[data.targetRegion],
		language: data.language.map(l => campaignBase.language[l]).join(', '),
		budget: campaignBase.budgetRanges[data.budget],
		schedule: `${data.startDate} ~ ${data.endDate}`,
		message: campaignBase.guideMessages[data.status].map((m, i) => (<p key={i}>{m}</p>)),
		buttonText: getButtonText(data.status)
	};

  return (
    <div className='campaign-item'>
			<div className='title'>
				<strong className='name'>{campaign.title}</strong>
				<span className='date'>{`생성일 ${campaign.createdAt}`}</span>
			</div>
			<div className='campaign-info'>
				<span className='photo'>
					<img src={campaign.thumbnailUrl} alt={campaign.title}/>
				</span>
				<div className='info-list'>
					<ul>
						<li><span>카테고리</span>{campaign.category}</li>
						<li><span>타겟 국가</span>{campaign.targetRegion}</li>
						<li><span>언어</span>{campaign.language}</li>
						<li><span>예산</span>{campaign.budget}</li>
						<li><span>일정</span>{campaign.schedule}</li>
					</ul>
				</div>
				<div className='status-box'>
					<div className={`status-bar ${data.status}`}>
						<strong><span></span></strong>
						<span className='txt-step1'>매칭중</span><span className='txt-step2'>진행중</span><span className='txt-step3'>진행완료</span>
					</div>
					<div className='textbox'>{campaign.message}</div>
				</div>
				<div className='btn-box'>
					{['new','matching'].includes(data.status) ? (
						<button type='button' className='btn-cont' disabled>{campaign.buttonText}</button>
					) : (
						<Link to={data.status === 'complete' ? `/report/${campaign.id}` : `/campaign/manage/${campaign.id}/step1`}>
							<button 
								type='button' 
								className={data.status === 'complete' ? 'btn-sub' : 'btn-cont'} >
								{campaign.buttonText}
							</button>
						</Link>
					)}
				</div>
			</div>
		</div>
  )
};

export default withRouter(CampaignItem);