"use strict";

const bucket = "globlin.public";
const region = "ap-northeast-2";
const baseURL = "amazonaws.com";
const folder = `campaign_report`;

var AWS = require("aws-sdk");
AWS.config.region = region; // 리전
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: `${process.env.REACT_APP_IdentityPoolId}`,
});

// Create a new service object
const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: bucket },
});

export const getExcelUrl = async (
  campaignId,
  sucessCallback,
  errorCallback
) => {
  try {
    const response = await s3
      .listObjects({ Prefix: `${folder}/${campaignId}` })
      .promise();

    const contents = response.Contents;
    const lastFileKey = contents[contents.length - 1].Key;
    const url = `https://s3.${region}.${baseURL}/${bucket}/${lastFileKey}`;

    sucessCallback(url);
  } catch (e) {
    console.log("our error", e);
    errorCallback();
  }
};
