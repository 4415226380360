import { call, put, takeLeading } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";
// import { save, create } from 'store/actions/Campaign';
// import history from 'utils/CustomHistory';

export const types = {};
types.SAVE = "SAVE";
types.CREATE = "CREATE";

export function* saveCampaign({ payload }) {
  yield put(setLoadingIndicatorVisibility(true));
  yield put(setLoadingIndicatorVisibility(false));
}

export function* createCampaign({ payload }) {
  yield put(setLoadingIndicatorVisibility(true));
  yield put(setLoadingIndicatorVisibility(false));
}

export function* watchCampaign() {
  yield takeLeading(types.SAVE, saveCampaign);
  yield takeLeading(types.CREATE, createCampaign);
}
