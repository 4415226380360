import React from 'react';
import { Link } from 'react-router-dom';

import base from 'data/campaign/basicInfo.json';

const CampaignPopup = (props) => {
  const { data, onClose } = props;

  const handleRegist = () => {
    alert('인플루언서 추천까지 영업일 기준, 최대 3일까지 소요될 수 있어요.\n매칭 완료 후 알림 메일을 보내 드릴게요.');
  }

  return (
    <div className='pop-wrap'>
      <div className='pop-content'>
        <h1 className='pop-h1'>등록한 캠페인 정보</h1>
        <dl className='info-list'>
          <div>
            <dt>캠페인 이름</dt>
            <dd>{data.title}</dd>
          </div>
          <div>
            <dt>카테고리</dt>
            <dd>{base.categories[data.category]}</dd>
          </div>
          <div>
            <dt>타겟 국가</dt>
            <dd>{base.targetRegion[data.targetRegion]}</dd>
          </div>
          <div>
            <dt>언어</dt>
            <dd>{data.language.map(l => base.language[l]).join(', ')}</dd>
          </div>
          <div>
            <dt>캠페인 목표</dt>
            <dd>{base.purposes[data.purpose]}</dd>
          </div>
          <div>
            <dt>예정 예산</dt>
            <dd>{base.budgetRanges[data.budget]}</dd>
          </div>
          <div>
            <dt>희망 일정</dt>
            <dd>{data.startDate} ~ {data.endDate}</dd>
          </div>
          <div>
            <dt>캠페인 유형</dt>
            <dd>{data.contentsTypes.join(', ')}</dd>
          </div>
          <div>
            <dt>선호 인플루언서 규모</dt>
            <dd>{base.subscriberCounts[data.preferedClass]}</dd>
          </div>
          <div>
            <dt>기타 필요 사항</dt>
            <dd>{data.etc}</dd>
          </div>
        </dl>
        <div className='pop-btn-wrap'>
          <button type='button' className='btn-line small' onClick={onClose}>수정</button>
          <Link to='/campaign'>
            <button type='button' className='btn-cont small' style={{marginLeft: '48px'}} onClick={handleRegist}>등록</button>
          </Link>
        </div>
        <button type='button' className='btn-close' onClick={onClose}>창닫기</button>
      </div>
    </div>
  );
};

export default CampaignPopup;