import { types } from 'store/sagas/Influencer';
const initialState = {
  show: false,
  category: -1,
  name: '',
  channelUrl: '',
  profilePicUrl: '',
  facebook: false,
  instagram: false,
  twitter: false,
  subsCount: [],
  content4weeks: [],
};

const influencerReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.SHOW_DETAIL:
      return {
        ...state,
        ...action.payload,
      };
    case types.HIDE_DETAIL:
      return initialState;
    default:
      return state;
  }
}

export default influencerReducer;

