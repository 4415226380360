import React, { Fragment } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Switch, Route, withRouter } from 'react-router-dom';

import Suggest from 'components/campaign/manage/Suggest';
import Select from 'components/campaign/manage/Select';
import Arrange from 'components/campaign/manage/Arrange';
import Review from 'components/campaign/manage/Review';

const CampaignManagePage = () => {

  return (
    <Fragment>
      <Header/>
      <div className='content-wrap'>
        <Switch>
          <Route exact path='/campaign/manage/:id/step1' component={Suggest} />
          <Route exact path='/campaign/manage/:id/step2' component={Select} />
          <Route exact path='/campaign/manage/:id/step3' component={Arrange} />
          <Route exact path='/campaign/manage/:id/step4' component={Review} />
        </Switch>
      </div>
      <Footer/>
    </Fragment>
  )
};

export default withRouter(CampaignManagePage);