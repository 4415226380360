import LoginPage from "pages/Login";
import CampaignRegistPage from "pages/CampaignRegist";
import CampaignListPage from "pages/CampaignList";
import CampaignManagePage from "pages/CampaignManage";
import ReportPage from "pages/Report";
import FitByCampaignPage from "pages/FitByCampaign";
import FitByChannelPage from "pages/FitByChannel";
import IntroPage from "pages/Intro";

const routes = [
  // {
  //   name: "campaign page",
  //   path: "/campaign/regist",
  //   component: CampaignRegistPage,
  // },
  // {
  //   name: "campaign page",
  //   path: "/campaign/manage/:id",
  //   component: CampaignManagePage,
  // },
  // {
  //   name: "campaign page",
  //   path: "/campaign",
  //   component: CampaignListPage,
  //   isExact: true,
  // },
  {
    name: "report page",
    path: "/report",
    component: ReportPage,
  },
  // {
  //   name: "fit by campaign page",
  //   path: "/fit/campaign",
  //   component: FitByCampaignPage,
  // },
  // {
  //   name: "fit by channel page",
  //   path: "/fit/channel",
  //   component: FitByChannelPage,
  // },
  {
    name: "login page",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "intro page",
    path: "/intro",
    component: IntroPage,
  },
  {
    name: "intro page",
    path: "/",
    component: IntroPage,
    isExact: true,
  },
];

export default routes;
