import { types } from 'store/sagas/Influencer';

export const showDetail = (payload) => ({
  type: types.SHOW_DETAIL,
  payload
});

export const hideDetail = () => ({
  type: types.HIDE_DETAIL,
});
