import { types } from "../sagas/Report";

const initialContent = {
  influencer: null,
  type: "",
  quantity: 0,
  status: -1,
};

export const initialState = {
  result: [],
};

const calculate = (contents) => {
  let sum = 0;
  let view = 0;
  let influencers = new Set([]);
  contents.forEach((c) => {
    sum += c.quantity;
    view +=
      c.influencer.viewByDate[c.influencer.viewByDate.length - 1] * c.quantity;
    influencers.add(c.influencer.id);
  });

  return {
    contents: contents,
    influencers: influencers,
    sum: sum,
    expectedView: view,
  };
};

const contentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_LIST_SUCCESS:
      return {
        result: action.payload,
      };

    case types.GET_REPORT_LIST_ERROR:
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default contentsListReducer;
