import { types } from 'store/sagas/Fit';

export const initialState = {
  target: undefined,
  candidate: undefined,
  matchedList: []
}
const fitReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.SET_TARGET:
      return {
        ...state,
        target: action.payload,
      };
    case types.SET_CANDIDATE:
      return {
        ...state,
        candidate: action.payload,
      };  
    case types.FIND:
      return {
        ...state,
        matchedList: action.payload,
      };
    default:
      return state;
  }
}

export default fitReducer;

