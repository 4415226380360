import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { save } from 'store/actions/Campaign';

import StepList from 'components/campaign/regist/StepList';
import CustomSelect from 'components/CustomSelect';
import LanguageSelect from 'components/LanguageSelect';
import base from 'data/campaign/basicInfo.json';

const InputDetails = () => {
	const campaign = useSelector(state => state.campaign);
  const dispatch = useDispatch();
	const history = useHistory();

	const [title, setTitle] = useState(campaign.title);
	const [category, setCategory] = useState(campaign.category);
	const [targetRegion, setTargetRegion] = useState(campaign.targetRegion);
	const [language, setLanguage] = useState(campaign.language);
	const [purpose, setPurpose] = useState(campaign.purpose);
	const [etc, setEtc] = useState(campaign.etc);

	
  const categoryHandler = (index) => {
		setCategory(index);
  };

  const regionHandler = (index) => {
		setTargetRegion(index);
	};
	
	const languageHandler = (index) => {
		setLanguage(index);
	}; 

  const purposeHandler = (index) => {
		setPurpose(index);
	};
	
	const etcHandler = (e) => {
		setEtc(e.currentTarget.value);
	}

	const validate = () => {
    if(title === '') return false;
    if(category < 0) return false;
		if(targetRegion < 0) return false;
		if(language.length === 0) return false;
		if(purpose === '') return false;
		
    return true;
  };

	const saveHandler = () => {
		if(!validate()) {
      alert('필수 값을 모두 입력/선택 해주세요.');
      return;
		}
		
    const payload = {
      title: title,
      category: category,
			targetRegion: targetRegion,
			language: language,
			purpose: purpose,
			etc: etc
    };
    dispatch(save(payload));

    history.push('/campaign/regist/step3');
	};
	
  return (
    <Fragment>
      <StepList />
      <div className='content-box'>
				<div className='info-set-wrap'>
					<div className='file-box'>
						<label htmlFor='file'>파일</label>
						<input type='file' id='file' />						
					</div>
					<div className='form-wrap'>
						<div className='flex-box'>
							<div className='form-box'>
								<label className='label-txt' htmlFor='name'>캠페인 이름</label>
								<input type='text' className='inp-txt' id='name' placeholder='캠페인 이름을 입력해주세요' onChange={e => setTitle(e.currentTarget.value)}/>
							</div>
							<div className='form-box'>
								<label className='label-txt' htmlFor='cate'>카테고리</label>
                <CustomSelect items={base.categories} defaultTitle='카테고리를 선택해주세요.' selectEvent={categoryHandler}/>
							</div>
							<div className='form-box'>
								<label className='label-txt' htmlFor='nation'>타겟국가</label>
                <CustomSelect items={base.targetRegion} defaultTitle='권역을 선택해주세요.' selectEvent={regionHandler}/>
							</div>
							<div className='lang-box'>
								<span className='label-txt' style={{height:'24px'}}>언어 <span>(복수 선택 가능)</span></span>
								<LanguageSelect items={base.language} defaultTitle='언어를 선택해주세요.' selectEvent={languageHandler}/>
							</div>
						</div>
						<div className='form-box'>
							<label className='label-txt' htmlFor='cate'>캠페인 목표</label>
              <CustomSelect items={base.purposes} defaultTitle='캠페인 목표를 선택해주세요.' selectEvent={purposeHandler}/>
						</div>
						<div className='form-box'>
							<label className='label-txt' htmlFor='cate'>기타 필요 사항 <span>(1000자 이내)</span></label>
							<textarea placeholder='원하시는 내용을 자유롭게 작성해 주세요
예시 - 수집형 RPG를 주로 다루는 게임 인플루언서를 추천해 주세요' onChange={etcHandler} />
						</div>
					</div>
				</div>
			</div>
      <div className='content-btn-box'>
        <Link to='/campaign/regist/step1'>
          <button type='button' className='btn-line'>이전</button>
        </Link>
          <button type='button' className='btn-cont' style={{marginLeft: '96px'}} onClick={saveHandler}>다음</button>
			</div>
    </Fragment>
  );
};

export default InputDetails;