import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const StepList = ({match}) => {

  return (
    <div className='step-list other'>
      <ul>
        <li>
          <NavLink to={`/campaign/manage/${match.params.id}/step1`} activeClassName='active'><span>1</span>추천</NavLink>
          <div className='tooltip-box'>도움말
            <div className='tooltip-base'>
              글로블린이 추천하는 캠페인 맞춤 인플루언서 리스트입니다.<br/>마음에 드는 인플루언서를 선택하고 스케줄 및 금액은<br/>[선택리스트]에서 요청해주세요.
            </div>
          </div>
        </li>
        <li>
          <NavLink to={`/campaign/manage/${match.params.id}/step2`} activeClassName='active'><span>2</span>선택</NavLink>
          <div className='tooltip-box'>도움말
            <div className='tooltip-base'>
				      추천 리스트에서 선택한 인플루언서 리스트 입니다.<br/>진행하고자 하는 캠페인 유형을 선택하면, 우측 선택리스트에 추가돼요.			
            </div>
          </div>
        </li>
        <li>
          <NavLink to={`/campaign/manage/${match.params.id}/step3`} activeClassName='active'><span>3</span>관리</NavLink>
          <div className='tooltip-box'>도움말
            <div className='tooltip-base'>
              인플루언서 실시간 캠페인 진행 상황입니다.
            </div>
          </div>
        </li>
        <li>
          <NavLink to={`/campaign/manage/${match.params.id}/step4`} activeClassName='active'><span>4</span>리뷰</NavLink>
          <div className='tooltip-box'>도움말
            <div className='tooltip-base'>
				      인플루언서의 영상 초안 및 업로드 된 영상을 확인할 수 있어요.
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(StepList);