import React, { Fragment, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { save } from 'store/actions/Campaign';

import base from 'data/campaign/basicInfo.json';
import StepList from 'components/campaign/regist/StepList';
import CustomSelect from 'components/CustomSelect';
import DatePicker from 'components/DatePicker';
import moment from 'moment';

const Budget = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [budget, setBudget] = useState(-1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const minDate = new Date(moment().add(14, 'days').format('YYYY-MM-DD'));

  const budgetHandler = (index) => {
    setBudget(index);
  };

  const validate = () => {
    if(budget === -1) return false;
    if(!startDate) return false;
    if(!endDate) return false;

    return true;
  }

  const saveHandler = () => {
    if(!validate()) {
      alert('필수 값을 모두 입력/선택 해주세요.');
      return;
    }

    const payload = {
      budget: budget,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    };
    dispatch(save(payload));

    history.push('/campaign/regist/step2');
  };

  return (
    <Fragment>
      <StepList />
      <div className='content-box'>
				<div className='form-wrap'>
					<div className='form-box'>
						<label className='label-txt' htmlFor='budget'>예정 예산</label>
            <CustomSelect items={base.budgetRanges} defaultTitle='예산과 캠페인 유형에 맞춰 인플루언서 리스트를 추천 드립니다.' selectEvent={budgetHandler}/>
					</div>
				</div>
				<h3 className='h3'>희망 일정
          <span>
            (콘텐츠 업로드 날짜)
            <div className='tooltip-box'>도움말
              <div className='tooltip-base'>
                인플루언서 매칭부터 캠페인 시작(콘텐츠 업로드)까지<br/>보통 2-3주가 걸립니다.<br/>희망 일정을 지금으로부터 최소 2주 뒤로 설정해주세요.
              </div>
            </div>
          </span>
        </h3>
				<div className='line-box'>
          <DatePicker minDate={minDate} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
				</div>
			</div>
      <div className='content-btn-box'>
        <button type='button' className='btn-cont' onClick={saveHandler}>다음</button>
			</div>
    </Fragment>
  );
};

export default withRouter(Budget);