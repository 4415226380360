import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";

import { login } from "store/actions/User";
import userList from "data/user.json";

const LoginPage = () => {
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const handleId = (e) => {
    setId(e.currentTarget.value);
  };

  const handlePw = (e) => {
    setPw(e.currentTarget.value);
  };

  const handleLogin = () => {
    //api로 요청, dispatch id, pw
    dispatch({ type: "LOGIN", payload: { username: id, password: pw } });

    // alert("유효하지 않은 아이디 입니다. 다시 입력해주세요.");
  };

  return (
    <Fragment>
      <Header />
      <div className="login-wrap">
        <span className="top-txt">Global all-in-one marketing platform</span>
        <h2>Globlin for Partner</h2>
        <div className="log-input">
          <span>
            <input
              type="text"
              className="inp-txt"
              placeholder="아이디"
              onChange={handleId}
            />
          </span>
          <span>
            <input
              type="password"
              className="inp-txt"
              placeholder="비밀번호"
              onChange={handlePw}
            />
          </span>
          {/* <input type="checkbox" id="loginUse" />
          <label htmlFor="loginUse" className="lb-check">
            로그인 상태 유지
          </label> */}
        </div>
        <button className="btn-cont" onClick={handleLogin}>
          로그인
        </button>
        <p>아이디 발급은 mkt@neicon.net으로 문의바랍니다.</p>
      </div>
      <Footer />
    </Fragment>
  );
};

export default LoginPage;
