import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { updateItem } from 'store/actions/ContentsList';
import { getShortenNumber, getClassNumber } from 'utils/NumberUtils';
import moment from 'moment';

const ReviewItem = (props) => {
  const { idx, data, contentType, status } = props;
  const contents = useSelector(state => state.contentsList);
  const dispatch = useDispatch();

  const [classNumber, setClassNumber] = useState(getClassNumber(status));

  const updateStatus = (status) => {
    const payload = {
      idx: idx,
      content: {...contents.contents[idx], status: status}
    }
    
    dispatch(updateItem(payload));
    setClassNumber(getClassNumber(status));
  }

  const handleConfirm = () => {
    updateStatus(7);
  }

  const handleModify = () => {
    updateStatus(4);
  }

  const handleOneMoreTime = () => {
    alert('인플루언서와 캠페인을 한번 더 진행해보세요.\n진행중인 캠페인에 추가하면, 추천리스트에서 볼 수 있어요.');
  }

	return (
    <div className='review-item'>
      <div className='name-tag'>
        <span><img src={data.profilePicUrl} alt={data.name}/></span>{data.name}
      </div>
      <div className='review-info-wrap'>
        <div className='info-box'>
          <ul>
            <li><span>구독자수</span>{getShortenNumber(data.subsCount[data.subsCount.length - 1])}</li>
            <li><span>평균 조회수</span>{getShortenNumber(data.avgView)}</li>
            <li><span>캠페인 유형</span>{contentType}</li>
          </ul>
        </div>
        <div className='status-box'>
          <div className={`status-bar ing${classNumber}`}>
            <strong><span></span></strong>
              <ul>
                <li className='txt-ing1'>제작중</li>
                <li className='txt-ing2'>초안 제출</li>
                <li className='txt-ing3'>수정중</li>
                <li className='txt-ing4'>수정 완료</li>
                <li className='txt-ing5'>컨펌 완료</li>
              </ul>
            </div>
            <div className='make-info'>
              <strong className='thum-img'>
                <img src={data.contentCampaign.thumbnailUrl} alt={data.name}/>
              </strong>
              <p><strong>예상 업로드일</strong><span>{moment(data.contentCampaign.date).format('YY/MM/DD')}</span></p>
              <button type='button' className='btn-cont xsmall'>제작가이드</button>
              <button type='button' className='btn-sub xsmall' onClick={handleModify}>수정요청</button>
            </div>
            <div className='form-wrap'>
              <label htmlFor='url' className='label-txt'>트레킹 URL</label>
              <input type='text' id='url' className='inp-txt' placeholder='트레킹 URL이 있을 경우 입력해주세요'/>
              <button type='button' className='btn-cont xsmall'>입력</button>
            </div>
						</div>
						<div className='btns-wrap'>
            {status === 7 ? (
              <div>
                <button type='button' className='btn-cont mid' onClick={handleOneMoreTime}>추가제안</button>
                <Link to='/report'>
                  <button type='button' className='btn-sub mid'>리포트 보기</button>
                </Link>
              </div>
            ) : (
              <div>
								<button type='button' className='btn-cont mid' onClick={handleConfirm} disabled={status===4}>확정</button>
							</div>
            )}
						</div>
					</div>
				</div>
  );
};

export default withRouter(ReviewItem);