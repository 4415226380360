import React, { Fragment } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Switch, Route, withRouter } from 'react-router-dom';

import Budget from 'components/campaign/regist/Budget';
import InputDetail from 'components/campaign/regist/InputDetail';
import TypeSelect from 'components/campaign/regist/TypeSelect';

const CampaignRegistPage = () => {
  return (
    <Fragment>
      <Header/>
      <div className='content-wrap'>
        <Switch>
          <Route exact path='/campaign/regist/step1' component={Budget} />
          <Route exact path='/campaign/regist/step2' component={InputDetail} />
          <Route exact path='/campaign/regist/step3' component={TypeSelect} />
        </Switch>
      </div>
      <Footer/>
    </Fragment>
  )
};

export default withRouter(CampaignRegistPage);