import React, { useState, Fragment, useEffect } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";

import CustomSelect from "components/CustomSelect";
import ReportTable from "components/report/ReportTable";
import ReportChart from "components/report/ReportChart";

import complete from "data/report/complete.json";
import { useDispatch, useSelector } from "react-redux";

import * as AWSAPI from "../api/aws";

//#region user define fuc
const getProcesseData = (rawData) => {
  let processedData = [
    {
      title: "",
      platform: [],
      campaignDate: "",
      campaignPeriod: "",
      data: [],
    },
  ];
  processedData = rawData.map((campaign) => ({
    title: campaign.name,
    campaignId: campaign.id,
    platform: campaign.platform,
    campaignDate: `${campaign.start_date} ~ ${campaign.end_date}`,
    campaignPeriod: getCampaignPeriod(campaign.start_date, campaign.end_date),
    data:
      campaign.video_set.length !== 0
        ? campaign.video_set.map((video_set) => ({
            name: video_set.channel_name,
            videoName: getVideoName(video_set),
            uploadDate: getProcessDate(video_set.published_at),
            avgView: getRecentView(video_set.schedule_data_set),
            viewCount: getViewCount(video_set.schedule_data_set),
            subscribeCount: video_set.subscriber_count,
            responseCount: getResponseCount(video_set.schedule_data_set),
            erCount: getErCount(video_set.schedule_data_set),
          }))
        : complete[0].data,
  }));
  return processedData;
};

const getCampaignPeriod = (start_date, end_date) => {
  const start = moment(start_date);
  const end = moment(end_date);
  const present = moment(Date.now());
  const startEnd = moment.duration(end.diff(start)).asDays();
  const startPresent = moment.duration(present.diff(start)).asDays() + 2;
  const ret = startEnd < startPresent ? startEnd : startPresent;

  return Math.round(ret);
};

const getRecentView = (schedule_data_set) => {
  const ret =
    schedule_data_set.length !== 0
      ? schedule_data_set[schedule_data_set.length - 1].view_count
      : 0;

  return ret;
};

const getVideoName = (video_set) => {
  const ret = video_set.video_name;
  return ret;
};

const getProcessDate = (rawDate) => {
  const ret = moment(`${rawDate}Z`).tz("Asia/Seoul").format("YYYY-MM-DD");
  return ret;
};

const getViewCount = (schedule_data_set) => {
  const ret = schedule_data_set.map((i) => i.view_count);
  return ret;
};

const getResponseCount = (schedule_data_set) => {
  const ret =
    schedule_data_set.length !== 0
      ? schedule_data_set[schedule_data_set.length - 1].like_count +
        schedule_data_set[schedule_data_set.length - 1].comment_count
      : 0;
  return ret;
};

const getErCount = (schedule_data_set) => {
  const responseCount = getResponseCount(schedule_data_set);
  const viewCount = getViewCount(schedule_data_set);
  const ret =
    schedule_data_set.length !== 0
      ? changeToPercent(responseCount / viewCount[viewCount.length - 1])
      : 0;
  return ret;
};

const changeToPercent = (decimal) => {
  //소수점 한자리까지 계산
  const ret = Math.round(decimal * 100 * 10) / 10;
  return ret;
};
//#endregion

const ReportPage = () => {
  const rawData = useSelector((state) => state.report.result);
  const [data, setData] = useState(complete);
  const [campaigns, setCampaigns] = useState(data.map((c) => c.title));
  const [platforms, setPlatforms] = useState(["유투브"]);
  const [influencers, setInfluencers] = useState([]);
  const [selectedCampaignIndex, setSelectedCampaignIndex] = useState(0);
  const [selectedInfluencer, setSelectedInfluencer] = useState("");
  const [chartData, setChartData] = useState();
  const dispatch = useDispatch();

  //초기에 리스트 요청
  useEffect(() => {
    dispatch({ type: "GET_REPORT_LIST" });
  }, []);

  //raw data 변경시마다 정제된 데이터로 교체
  useEffect(() => {
    //rawData가 유효하지 않을때는 return ;
    if (!rawData || rawData.length == 0) return;

    console.log("rawData 가공", rawData);

    //가공된 데이터로 변경
    let processedData = getProcesseData(rawData);
    setData(processedData);
  }, [rawData]);

  //정제된 데이터가 교체시마다 캠페인, 플랫폼, 인플루언서 등의 데이터 변경
  useEffect(() => {
    setCampaigns(data.map((c) => c.title));
    setPlatforms([data[selectedCampaignIndex].platform]);
    setInfluencers(data[selectedCampaignIndex].data.map((c) => c.name));
    setSelectedCampaignIndex(0);
  }, [data]);

  const campaignHandler = (index) => {
    setSelectedCampaignIndex(index);
    setInfluencers(data[index].data.map((c) => c.name));
  };

  const platformHandler = () => {
    if (selectedCampaignIndex >= 0 && selectedInfluencer === "") {
      influencerHandler(0);
    }
  };

  const influencerHandler = (index) => {
    if (selectedCampaignIndex < 0) {
      alert("먼저 캠페인을 선택해주세요.");
      return;
    } else {
      const rawData = data[selectedCampaignIndex].data[index];
      setSelectedInfluencer(`${rawData.name}'s `);

      const dateList = [];
      // const duration = data[selectedCampaignIndex].campaignPeriod;
      const duration = rawData.viewCount.length;

      for (let i = 1; i <= duration; i++) {
        const date = moment(rawData.uploadDate)
          .add(i, "days")
          .format("YYYY-MM-DD");
        dateList.push(date);
      }

      //chart관련
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "rgba(44,191,149,1)");
      gradient.addColorStop(1, "rgba(44,191,149,0)");

      setChartData({
        labels: dateList,
        datasets: [
          {
            label: false,
            yAxisID: "y1",
            fill: "start",
            backgroundColor: gradient,
            borderColor: "rgba(0, 176, 116, 1)",
            pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
            pointHoverBorderWidth: 3,
            pointRadius: 4,
            pointHoverRadius: 6,
            pointHoverBorderColor: "rgba(219, 95, 51, 1)",
            data: Object.values(rawData.viewCount),
          },
        ],
      });
    }
  };

  const downLoadHandler = () => {
    const campaignId = data[selectedCampaignIndex].campaignId;
    AWSAPI.getExcelUrl(
      campaignId,
      (url) => {
        const popup = window.open(
          url,
          "hiddenframe",
          "width=0, height=0, top=0, statusbar=no, scrollbars=no, toolbar=no"
        );

        popup.focus();
      },
      () => {
        console.log("downLoadHandler error");
      }
    );
  };

  return (
    <Fragment>
      <Header />
      <div className="content-wrap">
        <div className="report-wrap">
          <div className="top-btn">
            {/* 나중에 백앤드에서 주는 값으로 rawData를 변경하면 완료 */}
            <button
              onClick={downLoadHandler}
              type="button"
              className="btn-down"
              data={rawData}
            >
              내려받기
            </button>
          </div>
          <div className="report-top">
            <h2>{data[selectedCampaignIndex].title}</h2>
            <div className="info">
              <span className="platform">
                {data[selectedCampaignIndex].platform}
              </span>
              <span className="date">
                {data[selectedCampaignIndex].campaignDate}
              </span>
            </div>
          </div>
          <div className="filter-wrap">
            <div className="form-box">
              <label className="label-txt" htmlFor="name">
                캠페인
              </label>
              <CustomSelect
                items={campaigns}
                defaultTitle="캠페인을 선택해주세요"
                selectEvent={campaignHandler}
              />
            </div>
            <div className="form-box">
              <label className="label-txt" htmlFor="name">
                플랫폼
              </label>
              <CustomSelect
                items={platforms}
                defaultTitle="플랫폼을 선택해주세요"
                selectEvent={platformHandler}
              />
            </div>
            <div className="form-box">
              <label className="label-txt" htmlFor="name">
                인플루언서
              </label>
              <CustomSelect
                items={influencers}
                defaultTitle="인플루언서를 선택해주세요"
                selectEvent={influencerHandler}
                selectedFirst={true}
              />
            </div>
          </div>
          <h2>
            {selectedInfluencer}누적 조회수
            <div className="tooltip-box">
              도움말
              <div className="tooltip-base">
                각 인플루언서의 누적 조회수를 보려면 특정 인플루언서를
                선택하세요.
              </div>
            </div>
          </h2>
          <div className="chart-box">
            {chartData && <ReportChart data={chartData} />}
          </div>
          <h2>리포트</h2>
          <p className="txt-para">
            인플루언서 리포트는 콘텐츠 업로드 당일부터 14일간 집계됩니다.
            업로드일을 시작일로 집계하며 최종 수치는 14일 후 확정됩니다.
            <br />
            15일 이후의 데이터를 확인하시려면 상단의 RAW DATA 내려받기를
            클릭하여 주세요.
          </p>
          {selectedCampaignIndex !== -1 && (
            <ReportTable
              data={data[selectedCampaignIndex].data}
              influencers={influencers}
              chartPeriod={data[selectedCampaignIndex].campaignPeriod}
            />
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default withRouter(ReportPage);
