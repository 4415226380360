import { combineReducers } from "redux";
import user from "store/reducers/User";
import common from "store/reducers/Common";
import campaign from "store/reducers/Campaign";
import contentsList from "store/reducers/ContentsList";
import influencer from "store/reducers/Influencer";
import influencerList from "store/reducers/InfluencerList";
import fit from "store/reducers/Fit";
import report from "store/reducers/Report";

const rootReducer = combineReducers({
  user,
  common,
  campaign,
  contentsList,
  influencer,
  influencerList,
  fit,
  report,
});

export default rootReducer;
