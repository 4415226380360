import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { watchLogin } from "store/sagas/User";
import { watchCampaign } from "store/sagas/Campaign";
import { watchInfluencer } from "store/sagas/Influencer";
import { watchInfluencerList } from "store/sagas/InfluencerList";
import { watchContentsList } from "store/sagas/ContentsList";
import { watchReportList } from "store/sagas/Report";

export function* rootSaga() {
  yield all([
    watchLogin(),
    watchCampaign(),
    watchInfluencer(),
    watchInfluencerList(),
    watchContentsList(),
    watchReportList(),
  ]);
}

export default createSagaMiddleware();
