import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const StepList = () => {
  return (
    <div className='step-list'>
      <ul>
        <li><NavLink to='/campaign/regist/step1' activeClassName='active'><span>1</span>예산 및 희망일정</NavLink></li>
        <li><NavLink to='/campaign/regist/step2' activeClassName='active'><span>2</span>캠페인 정보 입력</NavLink></li>
        <li><NavLink to='/campaign/regist/step3' activeClassName='active'><span>3</span>캠페인 유형 선택</NavLink></li>
      </ul>
    </div>
  );
};

export default withRouter(StepList);