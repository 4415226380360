import { types } from "store/sagas/User";
const initialState = {
  id: null,
  loggedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        id: action.payload.username,
        loggedIn: true,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
      };
    case types.SET_USER_SUCCESS:
      return {
        ...state,
        id: action.payload.username,
        loggedIn: true,
      };
    case types.SET_USER_ERROR:
      return {
        ...state,
        loggedIn: false,
      };
    case types.LOGOUT:
      return {
        ...state,
        id: null,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default userReducer;
