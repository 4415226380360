import { takeLeading } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";

export const types = {};
types.UPDATE = "UPDATE";
types.UPDATE_ITEM = "UPDATE_ITEM";
types.DELETE_ITEM = "DELETE_ITEM";

export function* updateList({ payload }) {}

export function* updateItem({ payload }) {}

export function* deleteItem({ payload }) {}

export function* watchContentsList() {
  yield takeLeading(types.UPDATE, updateList);
  yield takeLeading(types.UPDATE_ITEM, updateItem);
  yield takeLeading(types.DELETE_ITEM, deleteItem);
}
