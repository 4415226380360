import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

//#region user define func

const DailyViewCountRow = (props) => {
  const { item, index, start } = props;
  const date = moment(start).add(index, "days").format("YYYY-MM-DD");
  const cols = [];
  item.forEach((v, i) => {
    cols.push(<td key={i}>{v}</td>);
  });

  return (
    <tr key={index}>
      <th className="fixed">{date.toString()}</th>
      {cols}
    </tr>
  );
};

const getInfluencersHeader = (influencers) =>
  influencers.map((i, idx) => (
    <th key={idx} className="fixed top">
      {i}
    </th>
  ));

//#endregion

const ReportTable = (props) => {
  const { data, influencers, chartPeriod } = props;
  const [influencersHeader, setInfluencersHeader] = useState(
    getInfluencersHeader(influencers)
  );

  const videoNameCells = [];
  const subscribeCountCells = [];
  const viewCountCells = [];
  const responseCountCells = [];
  const erCountCells = [];

  useEffect(() => {
    setInfluencersHeader(getInfluencersHeader(influencers, data));
  }, [data, influencers]);

  let minUploadDate = "99990101",
    maxUploadDate = "19000101";

  data.forEach((d) => {
    videoNameCells.push(
      <td
        key={d.videoName}
        className="fixed top-46"
        style={{ textAlign: "left" }}
      >
        {d.videoName}
      </td>
    );
    subscribeCountCells.push(
      <td key={d.videoName} className="fixed top-92">
        {d.subscribeCount}
      </td>
    );
    viewCountCells.push(
      <td key={d.videoName} className="fixed top-138">
        {d.avgView}
      </td>
    );
    responseCountCells.push(
      <td key={d.videoName} className="fixed top-184">
        {d.responseCount}
      </td>
    );
    erCountCells.push(
      <td key={d.videoName} className="fixed top-230">
        {d.erCount}
      </td>
    );

    if (d.uploadDate < minUploadDate) minUploadDate = d.uploadDate;
    if (d.uploadDate > maxUploadDate) maxUploadDate = d.uploadDate;
  });

  const dailyViewCountsByInfluencer = [];
  const start = moment(minUploadDate);
  const end = moment(maxUploadDate);
  const term = chartPeriod + 2;

  data.forEach((d) => {
    const offset = moment(d.uploadDate).diff(start, "days");
    const remain = end.diff(moment(d.uploadDate), "days");

    const head = Array(offset).fill("");
    const tail = Array(remain).fill("");

    const middle1 = head.concat(["업로드"]);
    const middle2 = middle1.concat(d.viewCount);
    const middle3 = middle2.concat(tail);
    dailyViewCountsByInfluencer.push(middle3);
  });

  const dailyViewCounts = Array.from(
    Array(term),
    () => new Array(influencers.length)
  );

  for (let i = 0; i < influencers.length; i++) {
    for (let j = 0; j < term; j++) {
      dailyViewCounts[j][i] = dailyViewCountsByInfluencer[i][j];
    }
  }

  const dailyViewCountRows = dailyViewCounts.map((item, index) => (
    <DailyViewCountRow
      key={index}
      item={item}
      index={index}
      start={start.format("YYYY-MM-DD")}
    />
  ));

  return (
    <div className="scrollable" style={{ height: "644px" }}>
      <table className="tbl-report">
        <colgroup>
          <col style={{ width: "100px" }} span="1" />
          <col style={{ width: "160px" }} span={influencers.length} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col" className="fixed top" style={{ zIndex: "101" }}>
              인플루언서
            </th>
            {influencersHeader}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="fixed top-46" style={{ zIndex: "101" }}>
              비디오
            </th>
            {videoNameCells}
          </tr>
          <tr>
            <th scope="row" className="fixed top-92" style={{ zIndex: "101" }}>
              구독자
            </th>
            {subscribeCountCells}
          </tr>
          <tr>
            <th scope="row" className="fixed top-138" style={{ zIndex: "101" }}>
              조회수
            </th>
            {viewCountCells}
          </tr>
          <tr>
            <th scope="row" className="fixed top-184" style={{ zIndex: "103" }}>
              반응수
              <div className="tooltip-box">
                <div className="tooltip-base">
                  좋아요수 + 댓글수
                  <br />
                  반응수는 좋아요수와 댓글수를 합한값으로
                  <br />
                  콘텐츠에 대한 반응을 알 수 있는 지표입니다.
                </div>
              </div>
            </th>
            {responseCountCells}
          </tr>
          <tr>
            <th scope="row" className="fixed top-230" style={{ zIndex: "102" }}>
              ER
              <div className="tooltip-box">
                <div className="tooltip-base">
                  ER은 반응수를 조회수로 나눈 값으로
                  <br />
                  콘텐츠에 대한 반응도를 알 수 있는 지표입니다.
                </div>
              </div>
            </th>
            {erCountCells}
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th
              colSpan="3"
              className="fixed top-276"
              style={{
                textAlign: "left",
                height: "46px",
                paddingLeft: "20px",
                borderRight: "none",
                zIndex: "101",
              }}
            >
              일간 조회수 (라이브 일부터 리포트가 집계됩니다.)
            </th>
            <th colSpan={influencers.length - 2} className="fixed top-276"></th>
          </tr>
          {dailyViewCountRows}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
