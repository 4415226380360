import React, {useState, Fragment} from 'react';

const CustomSelect = ({items, defaultTitle, selectEvent}) => {

  const randomId = `customSelect_${Math.floor(Math.random()*1000) + 1}`;
  const [state, setState] = useState({
      visibility : false,
      selected : new Set([]),
      hoverIndex: -1,
  });

  const toggle = () => {
    if(state.visibility) {
      close();
    } else {
      open();
    }
  }

  const open = () => {
      setState(s => ({ ...s, visibility: true }));
  };

  const close = (e) => {
      setState(s => ({ ...s, visibility: false }));
  };

  const onKeyPress = (e) => {
      e.preventDefault();

      if (e.key === 'ArrowUp') {
          if (state.hoverIndex < 1) return;
          setState({...state, hoverIndex: state.hoverIndex - 1});
      } else if (e.key === 'ArrowDown') {
          if (state.hoverIndex === items.length - 1) return;
          setState({...state, hoverIndex: state.hoverIndex + 1});
      } else if (e.key === 'Tab') {
          const nextEl = findNextTabElement();
          if(nextEl) nextEl.focus()
      } else if (e.key === 'Enter') {
          if(state.hoverIndex < 0) return;
          if(state.visibility === false) open();
          else {
              const [id, selectTitle] = Object.entries(items[state.hoverIndex])[0];
              setState({...state, hoverIndex: -1, visibility: false, selectTitle});
              selectEvent({target: {id}});
          }
      }
  };


  const findNextTabElement = () => {
      const el = document.querySelector(`#${randomId}`);
      const universe = document.querySelectorAll('input, button, select, textarea, a[href]');
      const list = Array.prototype.filter.call(universe, function(item) {return item.tabIndex >= "0"});
      const index = list.indexOf(el);
      return list[index + 1] || list[0];
  };

  const clickEvent = (e) => {
    const index = e.currentTarget.value;

    if(state.selected.has(index)) {
      state.selected.delete(index);
    } else {
      state.selected.add(index);
    }
    
    selectEvent([...state.selected]);

    setState(s => ({ ...s, visibility: true }));
  };

  const clear = () => {
    setState(s => ({ ...s, selected: new Set([]), visibility: true }));
  }

  const selectedList = [...state.selected].map((l,i) => <span key={i}>{items[l]}</span>);

  return (
    <div className={`lang-select-warp ${state.visibility ? 'open' : ''}`} id={randomId}>
      <div className='lang-select-box' onClick={toggle}>
      {state.selected.size === 0 ? (
        <span style={{lineHeight: '38px'}}>{defaultTitle}</span>
      ) : (
        <Fragment>
          <div className='lang-sel-list'>
            {selectedList}
          </div>
          <span className='count'>{state.selected.size}</span>
          <button type='button' className='btn-all-del' aria-label='전체삭제' onClick={clear}></button>
        </Fragment>
      )}
      </div>
      <button type='button' className='btn-open' aria-label='언어선택' onClick={toggle}/>
      <div 
        className='lang-select-conts'
        tabIndex={0} 
        onFocus={open} 
        onBlur={close} 
        onKeyDown={onKeyPress} >
        <ul>
          {items && items.map((item, index) => (
              <li
                key={`${randomId}_${index}`}
                value={index}
                rel={item}
                onMouseDown={clickEvent}>
                <input type='checkbox' id={`${randomId}_${index}`} defaultChecked={state.selected.has(index)}/>
                <label className='lb-check' htmlFor={`${randomId}_${index}`}>
                  <span>{item}</span>
                </label>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default CustomSelect;

