import React from 'react';
import { Line } from 'react-chartjs-2';

const InfluencerChart = (props) => {
  const { data } = props;
  const rawData = data.datasets[0].data;
  const min = rawData.reduce((previous, current) => {
    return previous > current ? current : previous;
  });
  const max = rawData.reduce((previous, current) => {
    return previous > current ? previous : current;
  });
  const step = (max - min) / 10;

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'point',
      intersect: true,
      backgroundColor: 'rgba(255,255,255,1)',
      borderColor: 'rgba(219, 95, 51, 1)',
      borderWidth: 1,
      titleFontStyle : 'normal',
      titleFontColor: 'rgba(219, 95, 51, 1)',
      titleFontSize: 14,
      bodyFontStyle : 'bold',
      bodyFontColor: 'rgba(219, 95, 51, 1)',
      bodyFontSize: 16,
      bodyAlign: 'center',
      cornerRadius : 0,
      displayColors: false,
      yPadding: 12,
      xPadding: 12,
      callbacks: {
        label: (item, data) => {
          return Intl.NumberFormat('en-US').format(item.value);
        }
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 13,
            callback: function(value, index, values) {
              const dateArray = value.split('-');
              return dateArray[1] + '/' + dateArray[2];
            }
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y1',
          ticks: {
            beginAtZero: false,
            fontSize: 13,
            stepSize: step,
            callback: function(value, index, values) {
              if(index === values.length - 1) return '';
              return Intl.NumberFormat('en-US').format(value);
            }
          }
        },
      ],
    }
  };

  return (
    <Line data={data} options={chartOptions}/>
  );

};

export default InfluencerChart;