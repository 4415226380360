import { types } from 'store/sagas/ContentsList';

export const updateList = (payload) => ({
  type: types.UPDATE,
  payload
});

export const updateItem = (payload) => ({
  type: types.UPDATE_ITEM,
  payload
});

export const deleteItem = (payload) => ({
  type: types.DELETE_ITEM,
  payload
});

