import { types } from 'store/sagas/Campaign';

export const initialState = {
  content: new Set([]),
}
const influencerListReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.UPDATE:
      return {
        content: action.payload,
      };
    default:
      return state;
  }
}

export default influencerListReducer;

