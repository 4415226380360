import { types } from 'store/sagas/ContentsList';

const initialContent = {
  influencer: null,
  type: '',
  quantity: 0,
  status: -1
}

export const initialState = {
  contents: [],
  influencers: new Set([]),
  sum: 0,
  expectedView: 0,
};

const calculate = (contents) => {
  let sum = 0;
  let view = 0;
  let influencers = new Set([]);
  contents.forEach(c => {
    sum += c.quantity;
    view += c.influencer.viewByDate[c.influencer.viewByDate.length - 1] * c.quantity;
    influencers.add(c.influencer.id)
  });

  return {
    contents: contents,
    influencers: influencers,
    sum: sum,
    expectedView: view
  }
}

const contentsListReducer = (state = initialState, action) => {


  switch(action.type) {
    case types.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPDATE_ITEM:
      // return {
      //   ...state,
      //   contents: [...state.contents.slice(0, action.payload.idx), action.payload.content, ...state.contents.slice(action.payload.idx + 1)]
      // }
      return calculate([...state.contents.slice(0, action.payload.idx), action.payload.content, ...state.contents.slice(action.payload.idx + 1)]);
    case types.DELETE_ITEM:
      return calculate([...state.contents.slice(0, action.payload.idx), ...state.contents.slice(action.payload.idx + 1)]);
      // let sum = 0;
      // let view = 0;
      // let influencers = new Set([]);
      // newContents.forEach(c => {
      //   sum += c.quantity;
      //   view += c.influencer.viewByDate[c.influencer.viewByDate.length - 1] * c.quantity;
      //   influencers.add(c.influencer.id)
      // });

      // return {
      //   contents: newContents,
      //   influencers: influencers,
      //   sum: sum,
      //   expectedView: view
      // }
    default:
      return state;
  }
}

export default contentsListReducer;

