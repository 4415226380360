import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { showDetail } from 'store/actions/Influencer';
import { setTarget, setCandidate } from 'store/actions/Fit';
import { getShortenNumber } from 'utils/NumberUtils';

const InfluencerItemForFit = (props) => {
	const { data, search = false } = props;

	const influencer = {
		...data,
		"tags": data.tags.map((t, i) => (<span key={i}>#{t}</span>)),
	};

	const fit = useSelector(state => state.fit);
	const selectSource = search ? fit.candidate : fit.target;
	const dispatch = useDispatch();

	const selectInfluencer = () => {
		if(search) {
			dispatch(setCandidate(influencer.name));
		} else {
			dispatch(setTarget(influencer.name));
		}
	};

	const showDetailPopup = () => {
		const payload = {
			...influencer,
			show: true
		}

		dispatch(showDetail(payload));
	};

  return (
    <div className={`influencer-item ${selectSource === influencer.name ? 'selected' : ''}`} onClick={selectInfluencer}>
			<div className='profile'>
				<span className='photo'><img src={influencer.profilePicUrl} alt={influencer.name} /></span>
				<div className='use-platform'>
					<a href={influencer.channelUrl} target='_blank' rel='noopener noreferrer' >
						<span className='youtube'>유튜브</span>
					</a>
					{influencer.facebook &&
						<span className='facebook'>페이스북</span>
					}
					{influencer.instagram &&
						<span className='instagram'>인스타그램</span>
					}
					{influencer.twitter &&
						<span className='twitter'>트위터</span>
					}
				</div>
			</div>
			<div className='info-wrap'>
				<div className='title'>
					<span className='cate'>{influencer.category}</span>
					<strong>{influencer.name}</strong>
				</div>
				<div className='tagbox'>
					{influencer.tags}
				</div>
				<div className='inf-index'>
					<ul>
						<li>
							<span>구독자</span><strong>{getShortenNumber(influencer.subsCount[influencer.subsCount.length - 1])}</strong>
						</li>
						<li>
							<span>AVG. View<button type='button' className='btn-tooltip'>도움말</button></span>
							<strong>{getShortenNumber(influencer.avgView)}</strong>
						</li>
						<li>
							<span>ER<button type='button' className='btn-tooltip'>도움말</button></span>
							<strong>{influencer.er}%</strong>
						</li>
						<li>
							<span>Score<button type='button' className='btn-tooltip'>도움말</button></span>
							<strong className={`star${influencer.globlinScore}`}></strong>
						</li>
					</ul>
				</div>
				<div className='btns-box'>
					<button type='button' className='btn-line middle' onClick={showDetailPopup}>세부정보</button>
				</div>
			</div>
			<div className='chart-wrap'>
				<div className='pie-chart chart1'>
					<span className='center'>유사도<strong>{influencer.similarity}%</strong></span>
				</div>
			</div>
		</div>
  )
};

export default withRouter(InfluencerItemForFit);