import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateList } from 'store/actions/ContentsList';
import CustomSelect from 'components/CustomSelect';
import { getShortenNumber } from 'utils/NumberUtils';
import influencerBasicInfo from 'data/influencer/basicInfo.json';

const InfluencerItemForSelect = (props) => {
	const { data, index } = props;

	const contentsList = useSelector(state => state.contentsList);
	const dispatch = useDispatch();
	const [type, setType] = useState('');

	const influencer = {
		...data,
		"tags": data.tags.map((t, i) => (<span key={i}>#{t}</span>))
	};

	const contentHandler = (index) => {
		setType(influencerBasicInfo.contentsTypes[index]);
	};

	const addContent = () => {
		const result = contentsList.contents.find(c => c.influencer.id === influencer.id && c.type === type);

		if(result === undefined) {
			contentsList.contents.push({
				influencer: influencer,
				type: type,
				quantity: 1
			});
		} else {
			const old = contentsList.contents.pop({
				influencer: influencer,
				type: type,
				quantity: 1
			});

			contentsList.contents.push({
				...old,
				quantity: old.quantity + 1
			})
		}

		let sum = 0;
		let view = 0;
		contentsList.contents.forEach(c => {
			sum += c.quantity;
			view += c.influencer.viewByDate[c.influencer.viewByDate.length - 1] * c.quantity;
		})

		const payload = {
			contents: contentsList.contents,
			influencers: contentsList.influencers.add(influencer.id),
			sum: sum,
			expectedView: view
		}

		dispatch(updateList(payload));
	}

  return (
    <div className='influencer-item'>
			<div className='profile'>
				<span className='photo'>
					<a href={influencer.channelUrl} target='_blank' rel='noopener noreferrer' >
						<img src={influencer.profilePicUrl} alt={influencer.name} />
					</a>		
				</span>
			</div>
			<div className='info-wrap'>
				<div className='title'>
					<span className='cate'>{influencer.category}</span>
					<strong>{influencer.name}</strong>
				</div>
				<div className='tagbox'>
					{influencer.tags}
				</div>
				<div className='inf-index'>
					<ul>
						<li>
							<span>구독자</span><strong>{getShortenNumber(influencer.subsCount[influencer.subsCount.length - 1])}</strong>
						</li>
						<li>
							<span>AVG. View
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										최근 20개 동영상의 평균 조회수
									</div>
								</div>
							</span>
							<strong>{getShortenNumber(influencer.avgView)}</strong>
						</li>
						<li>
							<span>ER
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										최근 20개 동영상의 "(좋아요 + 싫어요 + 댓글) / 조회수" 값
									</div>
								</div>	
							</span>
							<strong>{influencer.er}%</strong>
						</li>
						<li>
							<span>Score
								<div className='tooltip-box'>도움말
									<div className='tooltip-base'>
										자체적으로 부여한 캠페인 적합 점수
									</div>
								</div>	
							</span>
							<strong className={`star${influencer.globlinScore}`}></strong>
						</li>
						<li>
							<span>타겟국가</span><strong>{influencer.region}</strong>
						</li>
						<li>
							<span>언어</span><strong>{influencer.language}</strong>
						</li>
					</ul>
				</div>
			</div>
			<div className='check'>
				<CustomSelect items={influencerBasicInfo.contentsTypes} defaultTitle='콘텐츠 유형을 선택해주세요' selectEvent={contentHandler}/>
				<div className='btns-box'>
					<button type='button' className='btn-cont middle' onClick={addContent}>선택</button>
				</div>
			</div>
			<button type='button' className='btn-close' aria-label='삭제'></button>
		</div>
  )
};

export default withRouter(InfluencerItemForSelect);