import React from 'react';
import { useSelector } from 'react-redux';
import { Levels } from 'react-activity';
import 'react-activity/dist/react-activity.css';

const LoadingIndicator = () => {
  const on = useSelector(state => state.common.loadingIndicatorVisibility);
  const style = { display: on ? '' : 'none' };

  return (
    <div className='pop-wrap' style={style}>
      <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%'}}>
        <Levels color="#00b074" size={96} speed={1} animating={true} />
      </div>
    </div>
  );
};

export default LoadingIndicator;