import { takeLeading } from 'redux-saga/effects';
import {setLoadingIndicatorVisibility} from 'store/actions/Common';

export const types = {};
types.UPDATE = 'UPDATE';

export function* updateList({ payload }) {

};

export function* watchInfluencerList() {
    yield takeLeading(types.UPDATE, updateList);
}