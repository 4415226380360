export const getShortenNumber = (raw) => {

  const billion = 1000000000;
  const million = 1000000;
  const killo = 1000;

  if(raw >= billion) {
    return Math.round(raw / billion * 10) / 10 + 'B';
  }

  if(raw >= million) {
    return Math.round(raw / million * 10) / 10 + 'M';
  }

  if(raw >= killo) {
    return Math.round(raw / killo * 10) / 10 + 'K';
  }

  return raw;
}


export const getClassNumber = (status) => {
  switch(status) {
    case 7:
      return 5;
    case 4:
      return 3;
    case 5:
      return 4;
    case 3:
      return 2;
    default:
      return 1;
  }
}